/*
  codepen.io Embed Theme
  Author: Justin Perry <http://github.com/ourmaninamsterdam>
  Original theme - https://github.com/chriskempson/tomorrow-theme
*/

.hljs {
  display: block;
  overflow-x: auto;
  padding: 0.5em;
  background: #222;
  color: #fff;
}

.hljs-comment,
.hljs-quote {
  color: #777;
}

.hljs-variable,
.hljs-template-variable,
.hljs-tag,
.hljs-regexp,
.hljs-meta,
.hljs-number,
.hljs-built_in,
.hljs-builtin-name,
.hljs-literal,
.hljs-params,
.hljs-symbol,
.hljs-bullet,
.hljs-link,
.hljs-deletion {
  color: #ab875d;
}

.hljs-section,
.hljs-title,
.hljs-name,
.hljs-selector-id,
.hljs-selector-class,
.hljs-type,
.hljs-attribute {
  color: #9b869b;
}

.hljs-string,
.hljs-keyword,
.hljs-selector-tag,
.hljs-addition {
  color: #8f9c6c;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: bold;
}
